import React from "react";
import Img from "gatsby-image";
import linkedin from "../../assets/svgs/linkedin.svg";
import AvatarImg from "../../assets/images/avtar.png";
import Section from "../../components/Section/Section";
import fadeInSlide from 'utils/animations/fade-in-slide';

import "./LeadershipCards.scss";


const LeadershipCards = ({ sectionDescription, sectionHeading, cards }) => {
  const [ cardsRef ] = fadeInSlide('x', 25, { animateChildren: true });
  const [ titleRef ] = fadeInSlide('y', 25);

  return (
    <Section className="LeadershipCards">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h3 ref={titleRef} className="SectionTitle alpha0">{sectionHeading}</h3>
          </div>
          <div className="col-lg-6">
            <div className="SectionDescription Body--small">{sectionDescription}</div>
          </div>
        </div>
        <div ref={cardsRef} className="row alpha0-children">
          {cards.map((card, i) => {
            const cardImage = card.image?.localFile?.childImageSharp.fluid;
            return (
              <div className="col-lg-4" key={i}>
                <div className="LeadershipCard">
                  <div className="LeadershipCardImage">
                    {cardImage ? (
                      <Img fluid={cardImage} alt={card.name} />
                    ) : (
                      <img src={AvatarImg} alt={card.name} />
                    )}
                  </div>
                  <h4 className="LeadershipCardTitle">{card.name}</h4>
                  <div className="LeadershipCardDesignation Body--small">{card.designation}</div>
                  <a href={card.linkedinUrl} target="_blank" className="LinkedInProfile">
                    <img src={linkedin} alt="Linkedin" />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default LeadershipCards;
